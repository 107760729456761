import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'sharegallery';
const namespace = 'ui-pdp-icon ui-pdp-icon--share-gallery';

const IconShareGallery = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconShareGallery.propTypes = {
  className: string.isRequired,
};

IconShareGallery.ICON_ID = ICON_ID;

export default React.memo(IconShareGallery);
export { IconShareGallery };
